<template>
  <div id="amPie" ref="pieChart"></div>
</template>

<script>
import { mapState } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
am4core.options.autoDispose = true;

// import chartData from "@/assets/data/pieData.json";

export default {
  props: {
    info: {
      type: Array,
      required: true,
    },
  },
  methods: {
    renderChart() {
      am4core.useTheme(am4themes_animated);
      this.darkTheme
        ? am4core.useTheme(am4themes_dark)
        : am4core.useTheme(am4themes_material);

      let chart = am4core.create(this.$refs.pieChart, am4charts.PieChart);
      chart.data = this.info;
      // chart.data = chartData;

      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "litres";
      pieSeries.dataFields.category = "country";
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;

      // This creates initial animation
      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;
    },
  },
  mounted() {
    this.renderChart();
  },
  beforeDestroy() {
    if (this.chart) {
      am4core.disposeAllCharts();
    }
  },
  computed: {
    ...mapState("account", ["darkTheme"]),
  },
  watch: {
    darkTheme() {
      am4core.unuseAllThemes();
      if (this.chart) {
        am4core.disposeAllCharts();
      }
      this.renderChart();
    },
  },
};
</script>
